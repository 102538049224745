import React from 'react';
import { USCurrentStyle, USCurrentStyleDecimal } from '../../../utils';

// Helper function to render item names
const renderItemNames = (items) => {
  return items.map((item, x) => (
    <span key={x}>
      {item.name}
      {x === items.length - 1 ? ': ' : x === items.length - 2 ? ' & ' : ', '}
    </span>
  ));
};

// Helper function to render financing details
const renderFinancingOption = (
  minLoanRequired,
  totalFinanced,
  finalTotalCost,
  months,
  items
) => {
  if (minLoanRequired != null && totalFinanced >= minLoanRequired) {
    return (
      <p className='mb-0' key={months}>
        {renderItemNames(items)}
        <span>
          As low as {USCurrentStyleDecimal(finalTotalCost / months)} per month
          for {months} months with 0% financing
        </span>
      </p>
    );
  }

  return null;
};

// For Call Center Only
function CCContent({ option, optionKey, active }) {
  if (!option.items.length) return null;

  const minLoanRequirements = active.lender.minLoanRequired;
  return (
    <div className='mb-3'>
      <strong className='d-block mb-2'>Option {optionKey}</strong>

      {/* When the total financed is less than the 24 months min loan requirement  */}
      {/* != null will check for undefined and null only. It will accept 0 as a value */}
      {minLoanRequirements[24] != null &&
        option.totalFinanced < minLoanRequirements[24] && (
          <p className='mb-0'>
            {renderItemNames(option.items)}
            <span>
              Purchases less than {USCurrentStyle(minLoanRequirements[24])} can
              only be financed through myMILAN Financing
            </span>
          </p>
        )}

      {/* When the total financed is less than the 36 months min loan requirement && the lender does NOT accept 24 months plan  */}
      {!minLoanRequirements[24] &&
        minLoanRequirements[36] != null &&
        option.totalFinanced < minLoanRequirements[36] && (
          <p className='mb-0'>
            {renderItemNames(option.items)}
            <span>
              Only purchases greater than{' '}
              {USCurrentStyle(minLoanRequirements[36])} can be financed using{' '}
              {active.lender.name}
            </span>
          </p>
        )}

      {Object.keys(minLoanRequirements).map((key) => {
        return renderFinancingOption(
          minLoanRequirements[key],
          option.totalFinanced,
          option.finalTotalCost,
          key,
          option.items
        );
      })}
    </div>
  );
}

export default CCContent;
